import React, { useState, useEffect } from 'react';
import { FaArrowUp, FaPhone, FaEnvelope, FaMapMarkerAlt, FaTimes } from 'react-icons/fa';
import { CSSTransition } from 'react-transition-group';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import 'tailwindcss/tailwind.css';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { useUser } from '../components/contexts/UserContext';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import './RecaptchaHide.css'; // Import the CSS file

const stripePromise = loadStripe('your_stripe_public_key'); // Use your Stripe public key

const SupportUsPage = () => {
  const [showTopButton, setShowTopButton] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [banner, setBanner] = useState({ show: false, message: '', type: '' });
  const [fundraiseModalIsOpen, setFundraiseModalIsOpen] = useState(false);
  const [impactData, setImpactData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowTopButton(true);
      } else {
        setShowTopButton(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    updateImpactData();
  }, []);

  const updateImpactData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/impact`)
      .then(response => {
        setImpactData(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error("There was an error fetching the impact data!", error);
        setError("There was an error fetching the impact data.");
        setLoading(false);
      });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openFundraiseModal = () => {
    setFundraiseModalIsOpen(true);
  };

  const closeFundraiseModal = () => {
    setFundraiseModalIsOpen(false);
  };

  const showNotification = (message, type) => {
    setBanner({ show: true, message, type });
    setTimeout(() => {
      setBanner({ show: false, message: '', type: '' });
    }, 3000);
  };

  const handleDonateClick = () => {
    if (!user) {
      navigate('/register');
    }
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
      <Elements stripe={stripePromise}>
        <div className="min-h-screen bg-gray-50 dark:bg-gray-900 transition duration-500">
          {banner.show && (
            <div className={`fixed top-0 left-0 right-0 p-4 ${banner.type === 'success' ? 'bg-green-500' : 'bg-red-500'} text-white text-center`}>
              {banner.message}
            </div>
          )}
          <HeroSection />
          <div className="container mx-auto p-8 bg-white shadow-lg rounded-lg mt-10 dark:bg-gray-800 transition duration-500">
            <h1 className="text-4xl font-bold mb-8 text-center text-blue-700 dark:text-blue-300">Support Us</h1>
            <ImpactSection impactData={impactData} loading={loading} error={error} />
            <WaysToSupport openFundraiseModal={openFundraiseModal} />
            <DonationForm showNotification={showNotification} onDonateClick={handleDonateClick} updateImpactData={updateImpactData} />
            <VolunteerForm showNotification={showNotification} updateImpactData={updateImpactData} />
            <FAQ />
            <ContactInformation />
            {showTopButton && (
              <button
                onClick={scrollToTop}
                className="fixed bottom-8 right-8 bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 transition-colors duration-300 animate-bounce"
              >
                <FaArrowUp />
              </button>
            )}
          </div>
          <FundraiseForm isOpen={fundraiseModalIsOpen} onRequestClose={closeFundraiseModal} showNotification={showNotification} />
        </div>
      </Elements>
    </GoogleReCaptchaProvider>
  );
};

const HeroSection = () => (
  <div className="relative bg-blue-500 text-white text-center py-20 mb-10 overflow-hidden">
    <div className="absolute inset-0 opacity-30 animate-pulse">
      <img src="Media/Images/ezgif.com-webp-to-jpg-3.jpg" alt="Support Us" className="w-full h-full object-cover" />
    </div>
    <div className="relative z-10">
      <h1 className="text-5xl font-bold mb-4">Support the Diabetes Self-Help Group</h1>
      <p className="text-xl mb-8">Your support helps us provide valuable resources and assistance to those in need.</p>
    </div>
  </div>
);

const ImpactSection = ({ impactData, loading, error }) => {
  if (loading) {
    return <div className="flex justify-center items-center py-8"><div className="loader"></div></div>;
  }

  if (error) {
    return <div className="text-center text-red-500 py-8">{error}</div>;
  }

  if (!impactData) {
    return <div className="text-center text-gray-500 py-8">No impact data available.</div>;
  }

  return (
    <div className="mb-8">
      <h2 className="text-3xl font-bold text-teal-600 dark:text-teal-400 mb-4">Your Impact</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="p-6 bg-gradient-to-r from-purple-500 to-blue-500 text-white rounded-lg shadow-md text-center transition-transform transform hover:scale-105">
          <h3 className="text-2xl font-semibold mb-2">{impactData.peopleSupported}+</h3>
          <p className="leading-relaxed">People supported annually</p>
        </div>
        <div className="p-6 bg-gradient-to-r from-green-500 to-teal-500 text-white rounded-lg shadow-md text-center transition-transform transform hover:scale-105">
          <h3 className="text-2xl font-semibold mb-2">{impactData.volunteersEngaged}+</h3>
          <p className="leading-relaxed">Volunteers engaged</p>
        </div>
        <div className="p-6 bg-gradient-to-r from-pink-500 to-red-500 text-white rounded-lg shadow-md text-center transition-transform transform hover:scale-105">
          <h3 className="text-2xl font-semibold mb-2">£{impactData.amountRaised}</h3>
          <p className="leading-relaxed">Raised last year</p>
        </div>
      </div>
    </div>
  );
};

const WaysToSupport = ({ openFundraiseModal }) => (
  <div className="mb-8">
    <h2 className="text-3xl font-bold text-teal-600 dark:text-teal-400 mb-4">Ways to Support</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
      <SupportCard title="Donate" description="Your donations help us provide essential services and support to people with diabetes." link="#donate" />
      <SupportCard title="Volunteer" description="Join our team of volunteers and make a difference in the lives of people with diabetes." link="#volunteer" />
      <SupportCard title="Fundraise" description="Organize a fundraising event or campaign to support our mission." onClick={openFundraiseModal} />
      <SupportCard title="Spread Awareness" description="Help us spread the word about diabetes and our work by sharing our message with others." link="#spread-awareness" />
    </div>
  </div>
);

const SupportCard = ({ title, description, link, onClick }) => (
  <div className="mb-4 p-6 bg-white dark:bg-gray-700 rounded-lg shadow-md transition-transform transform hover:scale-105 hover:shadow-xl">
    <h3 className="text-xl font-semibold text-purple-600 dark:text-purple-300 mb-2">{title}</h3>
    <p className="leading-relaxed mb-4">{description}</p>
    <a href={link} className="text-blue-500 hover:underline" onClick={onClick}>Learn More</a>
  </div>
);

const DonationForm = ({ showNotification, onDonateClick, updateImpactData }) => {
  const [donationData, setDonationData] = useState({ name: '', email: '', amount: '', recurring: false });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setDonationData({ ...donationData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      showNotification('ReCAPTCHA not yet available', 'error');
      return;
    }

    const captchaToken = await executeRecaptcha('submit');

    setIsSubmitting(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/donate`, {
        name: donationData.name,
        email: donationData.email,
        amount: donationData.amount,
        recurring: donationData.recurring,
        captcha: captchaToken
      });

      const clientSecret = response.data.clientSecret;

      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: donationData.name,
            email: donationData.email,
          },
        },
      });

      if (error) {
        console.error(error);
        showNotification('Payment failed', 'error');
      } else {
        showNotification('Thank you for your donation!', 'success');
        setDonationData({ name: '', email: '', amount: '', recurring: false }); // Reset form
        updateImpactData(); // Update impact data
      }
    } catch (error) {
      console.error("There was an error submitting the donation!", error);
      showNotification('There was an error submitting the donation', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mb-8 bg-gray-50 dark:bg-gray-700 p-8 rounded-lg shadow-lg" id="donate">
      <h2 className="text-3xl font-bold text-teal-600 dark:text-teal-400 mb-6">Make a Donation</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="relative mb-4">
          <input
            type="text"
            id="name"
            name="name"
            value={donationData.name}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 peer"
            required
          />
          <label
            htmlFor="name"
            className={`absolute left-3 top-3 text-gray-500 dark:text-gray-300 transition-all transform ${donationData.name ? '-translate-y-6 scale-75' : 'translate-y-0 scale-100'} peer-focus:-translate-y-6 peer-focus:scale-75`}
          >
            Name
          </label>
        </div>
        <div className="relative mb-4">
          <input
            type="email"
            id="email"
            name="email"
            value={donationData.email}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 peer"
            required
          />
          <label
            htmlFor="email"
            className={`absolute left-3 top-3 text-gray-500 dark:text-gray-300 transition-all transform ${donationData.email ? '-translate-y-6 scale-75' : 'translate-y-0 scale-100'} peer-focus:-translate-y-6 peer-focus:scale-75`}
          >
            Email
          </label>
        </div>
      </div>
      <div className="relative mb-4">
        <input
          type="number"
          id="amount"
          name="amount"
          value={donationData.amount}
          onChange={handleChange}
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 peer"
          required
        />
        <label
          htmlFor="amount"
          className={`absolute left-3 top-3 text-gray-500 dark:text-gray-300 transition-all transform ${donationData.amount ? '-translate-y-6 scale-75' : 'translate-y-0 scale-100'} peer-focus:-translate-y-6 peer-focus:scale-75`}
        >
          Donation Amount
        </label>
      </div>
      <div className="mb-4">
        <label htmlFor="recurring" className="block text-gray-700 dark:text-gray-300 font-semibold mb-2">Recurring Donation</label>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="recurring"
            name="recurring"
            checked={donationData.recurring}
            onChange={handleChange}
            className="mr-2"
          />
          <span className="text-gray-700 dark:text-gray-300">Yes, make this a monthly donation</span>
        </div>
      </div>
      <div className="mb-4">
        <CardElement className="p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" />
      </div>
      <button
        type="submit"
        className="w-full bg-blue-500 text-white px-6 py-3 rounded-md shadow-md hover:bg-blue-700 transition-colors duration-300 mt-4"
        disabled={isSubmitting}
        onClick={onDonateClick}
      >
        {isSubmitting ? 'Submitting...' : 'Donate'}
      </button>
    </form>
  );
};

const VolunteerForm = ({ showNotification, updateImpactData }) => {
  const [volunteerData, setVolunteerData] = useState({ name: '', email: '', availability: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVolunteerData({ ...volunteerData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      showNotification('ReCAPTCHA not yet available', 'error');
      return;
    }

    const captchaToken = await executeRecaptcha('submit');

    setIsSubmitting(true);
    axios.post(`${process.env.REACT_APP_API_URL}api/volunteer`, {
      ...volunteerData,
      captcha: captchaToken
    })
      .then(response => {
        setIsSubmitting(false);
        showNotification('Thank you for signing up to volunteer!', 'success');
        setVolunteerData({ name: '', email: '', availability: '' }); // Reset form
        updateImpactData(); // Update impact data
      })
      .catch(error => {
        setIsSubmitting(false);
        console.error("There was an error submitting the volunteer form!", error);
        showNotification('There was an error submitting the volunteer form', 'error');
      });
  };

  return (
    <form onSubmit={handleSubmit} className="mb-8 bg-gray-50 dark:bg-gray-700 p-8 rounded-lg shadow-lg" id="volunteer">
      <h2 className="text-3xl font-bold text-teal-600 dark:text-teal-400 mb-6">Volunteer Sign-Up</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="relative mb-4">
          <input
            type="text"
            id="volunteer-name"
            name="name"
            value={volunteerData.name}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 peer"
            required
          />
          <label
            htmlFor="volunteer-name"
            className={`absolute left-3 top-3 text-gray-500 dark:text-gray-300 transition-all transform ${volunteerData.name ? '-translate-y-6 scale-75' : 'translate-y-0 scale-100'} peer-focus:-translate-y-6 peer-focus:scale-75`}
          >
            Name
          </label>
        </div>
        <div className="relative mb-4">
          <input
            type="email"
            id="volunteer-email"
            name="email"
            value={volunteerData.email}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 peer"
            required
          />
          <label
            htmlFor="volunteer-email"
            className={`absolute left-3 top-3 text-gray-500 dark:text-gray-300 transition-all transform ${volunteerData.email ? '-translate-y-6 scale-75' : 'translate-y-0 scale-100'} peer-focus:-translate-y-6 peer-focus:scale-75`}
          >
            Email
          </label>
        </div>
      </div>
      <div className="relative mb-4">
        <input
          type="text"
          id="availability"
          name="availability"
          value={volunteerData.availability}
          onChange={handleChange}
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 peer"
          required
        />
        <label
          htmlFor="availability"
          className={`absolute left-3 top-3 text-gray-500 dark:text-gray-300 transition-all transform ${volunteerData.availability ? '-translate-y-6 scale-75' : 'translate-y-0 scale-100'} peer-focus:-translate-y-6 peer-focus:scale-75`}
        >
          Availability
        </label>
      </div>
      <button
        type="submit"
        className="w-full bg-blue-500 text-white px-6 py-3 rounded-md shadow-md hover:bg-blue-700 transition-colors duration-300 mt-4"
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Submitting...' : 'Sign Up'}
      </button>
    </form>
  );
};

const FAQ = () => {
  const [faqs, setFaqs] = useState(null);
  const [openFAQ, setOpenFAQ] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}api/faqs`)
      .then(response => {
        setFaqs(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the FAQs!", error);
      });
  }, []);

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  if (!faqs) {
    return <div className="flex justify-center items-center py-8"><div className="loader"></div></div>;
  }

  return (
    <div className="faq bg-gray-50 dark:bg-gray-700 p-8 rounded-lg shadow-lg mb-8">
      <h3 className="text-3xl font-bold text-teal-600 dark:text-teal-400 mb-6">Frequently Asked Questions</h3>
      {faqs.map((faq, index) => (
        <div key={index} className="mb-6">
          <h4
            className="font-semibold text-gray-800 dark:text-gray-100 cursor-pointer"
            onClick={() => toggleFAQ(index)}
          >
            {faq.question}
          </h4>
          <CSSTransition
            in={openFAQ === index}
            timeout={300}
            classNames="faq-content"
            unmountOnExit
          >
            <p className="text-gray-700 dark:text-gray-300 leading-relaxed mt-2">{faq.answer}</p>
          </CSSTransition>
        </div>
      ))}
    </div>
  );
};

const ContactInformation = () => (
  <div className="mb-8">
    <h2 className="text-3xl font-bold text-teal-600 dark:text-teal-400 mb-4">Contact Information</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <div className="flex items-center mb-4">
        <FaPhone className="text-blue-500 mr-4" />
        <p className="leading-relaxed">Phone: <a href="tel:07921565385" className="text-blue-500 hover:underline">07921565385</a></p>
      </div>
      <div className="flex items-center mb-4">
        <FaEnvelope className="text-blue-500 mr-4" />
        <p className="leading-relaxed">Email: <a href="mailto:dgleicester@gmail.com" className="text-blue-500 hover:underline">dgleicester@gmail.com</a></p>
      </div>
      <div className="flex items-center mb-4">
        <FaMapMarkerAlt className="text-blue-500 mr-4" />
        <p className="leading-relaxed">Address: Leicester, UK</p>
      </div>
    </div>
  </div>
);

const FundraiseForm = ({ isOpen, onRequestClose, showNotification }) => {
  const [fundraiseData, setFundraiseData] = useState({ name: '', email: '', eventDetails: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFundraiseData({ ...fundraiseData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    axios.post(`${process.env.REACT_APP_API_URL}/api/fundraise`, fundraiseData)
      .then(response => {
        setIsSubmitting(false);
        showNotification('Thank you for organizing a fundraising event!', 'success');
        setFundraiseData({ name: '', email: '', eventDetails: '' }); // Reset form
        onRequestClose();
      })
      .catch(error => {
        setIsSubmitting(false);
        console.error("There was an error submitting the fundraising form!", error);
        showNotification('There was an error submitting the fundraising form', 'error');
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white dark:bg-gray-700 p-8 rounded-lg shadow-lg relative max-w-lg w-full">
        <button onClick={onRequestClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <FaTimes className="w-6 h-6" />
        </button>
        <h2 className="text-3xl font-bold mb-4 text-center text-teal-600 dark:text-teal-400">Fundraise for Us</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="relative">
            <input
              type="text"
              id="fundraise-name"
              name="name"
              value={fundraiseData.name}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500 peer"
              required
            />
            <label
              htmlFor="fundraise-name"
              className={`absolute left-3 top-3 text-gray-500 dark:text-gray-300 transition-all transform ${fundraiseData.name ? '-translate-y-6 scale-75' : 'translate-y-0 scale-100'} peer-focus:-translate-y-6 peer-focus:scale-75`}
            >
              Name
            </label>
          </div>
          <div className="relative">
            <input
              type="email"
              id="fundraise-email"
              name="email"
              value={fundraiseData.email}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500 peer"
              required
            />
            <label
              htmlFor="fundraise-email"
              className={`absolute left-3 top-3 text-gray-500 dark:text-gray-300 transition-all transform ${fundraiseData.email ? '-translate-y-6 scale-75' : 'translate-y-0 scale-100'} peer-focus:-translate-y-6 peer-focus:scale-75`}
            >
              Email
            </label>
          </div>
          <div className="relative">
            <textarea
              id="eventDetails"
              name="eventDetails"
              value={fundraiseData.eventDetails}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500 peer"
              required
            />
            <label
              htmlFor="eventDetails"
              className={`absolute left-3 top-3 text-gray-500 dark:text-gray-300 transition-all transform ${fundraiseData.eventDetails ? '-translate-y-6 scale-75' : 'translate-y-0 scale-100'} peer-focus:-translate-y-6 peer-focus:scale-75`}
            >
              Event Details
            </label>
          </div>
          <button
            type="submit"
            className="w-full bg-teal-500 text-white px-6 py-3 rounded-md shadow-md hover:bg-teal-700 transition-colors duration-300"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default SupportUsPage;
