import React from 'react';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { GiSelfLove } from 'react-icons/gi';
import { IoMdPeople } from 'react-icons/io';

const HistoryPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 py-12">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8 text-center text-blue-700 dark:text-blue-300">History of the Diabetes Self-Help Group</h1>

        {/* New Section with Two Images Stacked Vertically */}
        <section className="bg-white p-8 rounded-lg shadow-lg dark:bg-gray-800 mb-8">
          <div className="flex justify-center items-center p-2 mb-4">
            <img
              src={`https://diabetesselfhelpgroup.co.uk/Media/Images/History1.jpg`}
              alt="Weekly Meetings Image"
              className="w-full md:w-auto h-auto rounded-lg shadow-md mb-4"
            />
          </div>
          <div className="flex justify-center items-center p-2 mb-4">
            <img
              src={`https://diabetesselfhelpgroup.co.uk/Media/Images/History2.png`}
              alt="Weekly Meetings Image"
              className="w-full md:w-auto h-auto rounded-lg shadow-md mb-4"
            />
          </div>
        </section>

        <section className="bg-white p-8 rounded-lg shadow-lg dark:bg-gray-800 mb-8">
          <h2 className="text-3xl font-bold text-teal-600 dark:text-teal-400 mb-4">Our Journey</h2>
          <div className="timeline">
            <div className="timeline-item mb-8 flex items-start">
              <div className="timeline-icon bg-teal-600 dark:bg-teal-400 text-white rounded-full p-2 shadow-lg">
                <GiSelfLove size={24} />
              </div>
              <div className="timeline-content ml-4 p-4 bg-gray-100 dark:bg-gray-700 rounded-lg shadow-md">
                <h3 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">2004</h3>
                <p className="text-lg text-gray-700 dark:text-gray-300">Dr. Sonal Bhavsar established the group after a personal gestational diabetes diagnosis.</p>
              </div>
            </div>
            <div className="timeline-item mb-8 flex items-start">
              <div className="timeline-icon bg-teal-600 dark:bg-teal-400 text-white rounded-full p-2 shadow-lg">
                <IoMdPeople size={24} />
              </div>
              <div className="timeline-content ml-4 p-4 bg-gray-100 dark:bg-gray-700 rounded-lg shadow-md">
                <h3 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">2006</h3>
                <p className="text-lg text-gray-700 dark:text-gray-300">Moved to Belgrave Neighbourhood Centre due to increased interest and membership.</p>
              </div>
            </div>
            <div className="timeline-item flex items-start">
              <div className="timeline-icon bg-teal-600 dark:bg-teal-400 text-white rounded-full p-2 shadow-lg">
                <FaMapMarkerAlt size={24} />
              </div>
              <div className="timeline-content ml-4 p-4 bg-gray-100 dark:bg-gray-700 rounded-lg shadow-md">
                <h3 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">2020</h3>
                <p className="text-lg text-gray-700 dark:text-gray-300">Reached 100 registered members, continuing to support the community.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white p-8 rounded-lg shadow-lg dark:bg-gray-800 mb-8">
          <h2 className="text-3xl font-bold text-teal-600 dark:text-teal-400 mb-4">Who Can Join</h2>
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 text-lg">
            <li>If you have Type 1 or Type 2 Diabetes</li>
            <li>If you are on tablets or insulin</li>
            <li>If you are diagnosed with Borderline Diabetes</li>
            <li>If a family member has Diabetes, putting you at high risk</li>
            <li>If you are a carer for a diabetic patient</li>
            <li>Anyone seeking more information on Diabetes</li>
          </ul>
        </section>

        <section className="bg-white p-8 rounded-lg shadow-lg dark:bg-gray-800 mb-8">
          <h2 className="text-3xl font-bold text-teal-600 dark:text-teal-400 mb-4">What We Do</h2>
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 text-lg">
            <li>Share personal experiences on diet, exercise, and lifestyle in relation to Diabetes.</li>
            <li>Invite health professionals to offer advice, information, and guidance.</li>
            <li>Discuss various health topics in weekly meetings, including diet, stress, exercise, and self-management.</li>
            <li>Conduct activities such as local trips, picnics, and social activities like jewelry making and educational sessions on health and well-being.</li>
          </ul>
        </section>

        <section className="bg-white p-8 rounded-lg shadow-lg dark:bg-gray-800 mb-8">
          <h2 className="text-3xl font-bold text-teal-600 dark:text-teal-400 mb-4">Where We Meet</h2>
          <div className="mb-4">
            <h3 className="text-2xl font-semibold text-blue-600 dark:text-blue-300">Wednesdays</h3>
            <p className="text-lg text-gray-700 dark:text-gray-300">
              10:00 am – 11:30 am at the Belgrave Neighbourhood Centre, Rothley Street, Leicester, LE4 6FL, Tel: 0116 222 1004. Admission: £1.
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-semibold text-blue-600 dark:text-blue-300">Saturdays</h3>
            <p className="text-lg text-gray-700 dark:text-gray-300">
              10:30 am – 12:30 pm at the Belgrave Library, 24 Cossington Street, Leicester, LE4 6JD, Tel: 0116 299 5500. Admission: Free.
            </p>
            <div className="mt-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2428.5570234731667!2d-1.1298020842335094!3d52.65542847983616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4877617a7d28cb2b%3A0x1c65b12a0ff525b!2sBelgrave%20Library!5e0!3m2!1sen!2suk!4v1623426190121!5m2!1sen!2suk"
                width="100%"
                height="400"
                allowFullScreen=""
                loading="lazy"
                className="rounded-lg shadow-md"
                title="Belgrave Library Location"
              ></iframe>
            </div>
          </div>
        </section>

        <section className="bg-white p-8 rounded-lg shadow-lg dark:bg-gray-800 mb-8">
          <h2 className="text-3xl font-bold text-teal-600 dark:text-teal-400 mb-4">Get Involved!</h2>
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 text-lg">
            <li>Volunteer your time or expertise to help us fundraise and organize events.</li>
            <li>Donate to support our mission and help expand our services.</li>
            <li>Provide suggestions for health events, sponsoring, and marketing opportunities.</li>
          </ul>
        </section>

        <section className="bg-white p-8 rounded-lg shadow-lg dark:bg-gray-800 mb-8">
          <h2 className="text-3xl font-bold text-teal-600 dark:text-teal-400 mb-4">Contact Information</h2>
          <div className="flex flex-col space-y-4 text-lg">
            <div className="flex items-center">
              <FaPhone className="text-blue-500 mr-4" />
              <p className="leading-relaxed">Phone: <a href="tel:+1234567890" className="text-blue-500 hover:underline">07921565385</a></p>
            </div>
            <div className="flex items-center">
              <FaEnvelope className="text-blue-500 mr-4" />
              <p className="leading-relaxed">Email: <a href="mailto:dgleicester@gmail.com" className="text-blue-500 hover:underline">dgleicester@gmail.com</a></p>
            </div>
            <div className="flex items-center">
              <FaMapMarkerAlt className="text-blue-500 mr-4" />
              <p className="leading-relaxed">Address: Belgrave, Leicester, UK</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HistoryPage;
